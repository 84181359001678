@tailwind base;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  h1 {
    @apply text-8;
    @apply leading-5;
    @apply font-nato-semibold;
  }
  h2 {
    @apply text-8;
    @apply leading-5;
    @apply font-nato-medium;
  }
  h3 {
    @apply text-2xl;
    @apply font-nato-semibold;
  }
  h4 {
    @apply text-2xl;
    @apply font-nato-medium;
  }
  h5 {
    @apply text-xl;
    @apply font-nato-semibold;
  }
  h6 {
    @apply text-xl;
    @apply font-nato-medium;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'NotoSans-Light';
  src: url('/assets/fonts/nato-sans/NotoSans-Light.woff2') format('woff2'),
    url('/assets/fonts/nato-sans/NotoSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans';
  src: url('/assets/fonts/nato-sans/NotoSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/nato-sans/NotoSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Medium';
  src: url('/assets/fonts/nato-sans/NotoSans-Medium.woff2') format('woff2'),
    url('/assets/fonts/nato-sans/NotoSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-SemiBold';
  src: url('/assets/fonts/nato-sans/NotoSans-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/nato-sans/NotoSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoSans-Bold';
  src: url('/assets/fonts/nato-sans/NotoSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/nato-sans/NotoSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: NotoSans, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input[type='radio'] {
  accent-color: #0f8040;
}
input[type='radio']:hover {
  accent-color: #0f8040;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type='number'].remove-spin-btns::-webkit-outer-spin-button,
input[type='number'].remove-spin-btns::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
